/* ==========================================================================
   FOOTER
   ========================================================================== */
.branch-text {
	font-size:14px !important;
	a {
		color:#00A0E8;
	}
}
.slogan-bottom {
	padding-top: 50px;
	padding-bottom: 70px;
	@media (max-width:768px - 1px) { // sm
		padding-top: 30px;
		padding-bottom: 30px;
	}
	h6 {
		font-size: 24px;
		color: #00A0E8;
	}
	p {
		color:#666666;
	}
}
.footer {
	border-top:1px solid #EAEAEA;
	padding:20px 0 80px;
	@media (max-width:992px - 1px) { // md
		border-top:0;
		padding:0 0 20px;
	}
	@media (max-width:768px - 1px) { // sm
		padding:0 15px 30px;;
	}
	.box {
		width: 70%;
		@media (max-width:992px - 1px) { // md
			border-top:1px solid #EAEAEA;
			width:100%;
			padding-top:20px;
		}
		.nav {
			flex:1;
			@media (max-width:992px - 1px) { // md
				justify-content: space-between;
				padding-bottom:30px;
			}
			.item {
				flex:1;
				width:100px;
				@media (max-width:992px - 1px) { // md
					flex:none;
					width:auto;
				}
				&:first-child {
					flex: 0.75;
					@media (max-width:992px - 1px) { // md
						flex:none;
					}
				}
				a {
					display:block;
					font-size:13px;
					color:#666;
					@media (max-width:992px - 1px) { // md
						font-size:12px;
					}
				}
			}
		}
		.logo {
			justify-content:space-between;
			align-items: flex-start;
			padding-right:8%;
			width: 32%;
			@media (max-width:992px - 1px) { // md
				justify-content:center;
				padding-right:0;
				padding-bottom:40px;
				width:100%;
			}
			img {
				width:80px;
				margin-right:10px;
				@media (max-width:992px - 1px) { // md
					margin:0 20px;
				}
			}
		}
	}
	.copyright {
		font-size:13px;
		color:#666;
		margin:0;
		text-align:right;
		@media (max-width:992px - 1px) { // md
			text-align:center;
		}
	}
}