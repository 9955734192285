/* ==========================================================================
   HEADER
   ========================================================================== */
.header {
	position:relative;
	z-index:2;
	@media (max-width:992px - 1px) { // md
		position:static;
	}
	.notice-bar {
		background-color:#273A42;
		height:50px;
		.container {
			display:flex;
			justify-content: space-between;
			align-items:center;
			height:100%;
			p {
				color:#fff;
				margin:0;
				@media (max-width:992px - 1px) { // md
					font-size:13px;
				}
			}
			a {
				color:#fff;
			}
			.close-btn {
				width:24px;
				cursor:pointer;
			}
			.notice-swiper {
				position: relative;
    		overflow: hidden;
				width:100%; height:100%;
				.swiper-wrapper {
					display:block !important;
					.swiper-slide {
						display:flex;
						justify-content: space-between;
						align-items:center;
					}
				}
			}
		}
	}
	.mobile-menu {
		position:relative;
		justify-content: center;
		align-items: center;
		height:60px;
		box-shadow: 0 0 8px rgba(0,0,0,0.2);
		.menu-btn {
			position:absolute;
			top:50%; left:15px;
			transform:translate(0, -50%);
			display:flex;
			flex-direction: column;
			justify-content: space-between;
			width:32px; height:19px;
			span {
				display:block;
				width:100%; height:3px;
				background-color:#00A0E8;
			}
		}
		.logo {
			width:70px;
		}
	}
	.menu-container {
		height:107px;
		@media (max-width:992px - 1px) { // md
			position:fixed;
			top:0; left:0;
			width:250px; min-height:calc(var(--vh, 1vh) * 100);
			background-color:#fff;
			z-index: 1;
			overflow-y:auto;
			transform:translate(-250px, 0);
			padding:20px 0;
			z-index: 10;
			transition:all .4s ease;
		}
		.container {
			height: 100%;
			@media (max-width:992px - 1px) { // md
				height:auto;
			}
			.inner-box {
				display:flex;
				justify-content: space-between;
				align-items:center;
				height:100%;
				@media (max-width:992px - 1px) { // md
					flex-direction: column;
					padding:0;
				}
				.nav-container {
					display:flex;
					align-items:center;
					@media (max-width:992px - 1px) { // md
						flex-direction: column;
						width:100%;
					}
					.logo {
						width:127px;
						margin-right:30px;
						@media (max-width:1200px - 1px) { // lg
							margin-right:30px;
						}
						@media (max-width:992px - 1px) { // md
							margin:0 auto 20px;
						}
					}
					.navbar-nav {
						// display:flex;
						@media (max-width:992px - 1px) { // md
							// flex-direction: column;
							width:100%;
							padding-bottom:20px;
						}
						.nav-item {
							margin-left:32px;
							// @media (max-width:1200px - 1px) { // lg
							// 	margin-left:25px;
							// }
							@media (max-width:992px - 1px) { // md
								margin-left:0;
							}
							&:first-child {
								margin-left:0;
							}
							&.active {
								a {
									color:#00A0E8;
								}
							}
							a {
								font-size:14px;
								white-space: nowrap;
								@media (max-width:992px - 1px) { // md
									font-size:16px;
									padding: .25rem 1.5rem;
								}
							}
							.dropdown-menu {
								padding:20px 0;
								border:0;
								border-radius: 8px;
								box-shadow: 0 0 4px 0 rgba(0,0,0,0.20);
								min-width:auto;
								white-space: nowrap;
								&.show {
									display:block;
									@media (max-width:992px - 1px) { // md
										display:block;
									}
								}
								@media (max-width:992px - 1px) { // md
									position: static !important;
									float: none;
									transform:none !important;
									padding:0 0 0 20px;
									margin:0;
									box-shadow:none;
								}
								.dropdown-item-box {
									display:inline-block;
									vertical-align: top;
									@media (max-width:992px - 1px) { // md
										display:block;
									}
									&:first-child {
										border-right:1px solid #f5f5f5;
									}
									&.border-none {
										border:none;
									}
									.dropdown-item-text {
										white-space: nowrap;
										@media (max-width:992px - 1px) { // md
											font-weight:600;
										}
									}
									.dropdown-item {
										color:#1A1A1A;
										transition:.3s ease;
										&:active {
											background-color:#efefef;
										}
										&.active {
											color:#00A0E8;
											background-color:transparent;
										}
										@media (max-width:992px - 1px) { // md
											padding-left:35px;
										}
									}
								}
							}
						}
					}
				}
				.btn-box {
					display:flex;
					justify-content: start;
					align-items: center;
					@media (max-width:992px - 1px) { // md
						flex-direction: column;
					}
					a {
						white-space: nowrap;
					}
					.tel {
						position:relative;
						display:block;
						border: 2px solid rgba(95,198,244,0.26);
						border-radius: 22px;
						background-color:#fff;
						color:#00A0E8;
						padding:9px 25px 8px 50px;
						text-decoration: none;
						margin-right:30px;
						text-align:center;
						transition:.4s ease;
						line-height: 1.3;
						@media (max-width:1200px - 1px) { // lg
							margin-right:15px;
						}
						@media (max-width:992px - 1px) { // md
							width: 100%;
							margin-bottom:15px;
						}
						&:hover {
							transform:scale(0.95);
							opacity:.8;
						}
						img {
							position:absolute;
							top:50%; left:15px;
							transform:translate(0, -50%);
							width:30px;
						}
					}
					.form-btn {
						color:#fff;
						background-image: linear-gradient(150deg, #6CDDF8 0%, #3AB8EE 100%);
						border: 2px solid rgba(95,198,244,0.26);
						border-radius: 22px;
						padding:9px 15px;
						text-decoration: none;
						transition:.4s ease;
						&:hover {
							transform:scale(0.95);
							opacity:.8;
						}
					}
				}
			}
		}
	}
}
.menu-dim {
	position:absolute;
	top:0; left:0;
	width:100%; height:100%;
	background-color:rgba(0,0,0,0.5);
	z-index: 2;
	opacity:0;
	visibility: hidden;
	transition:.4s ease;
	.menu-open & {
		opacity:1;
		visibility: visible;
	}
}