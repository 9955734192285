@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+HK:wght@100;300;400;500&display=swap');

html,
body {
	background-color: #fff;
	// width: 100%;
	// height: 100%;
	font-family: 'Noto Sans HK', Arial, Helvetica, "微軟正黑體", "Microsoft JhengHei", "Heiti TC", "LiHei Pro", "新細明體", PMingLiU, sans-serif;
	color: #1A1A1A;
	-webkit-text-size-adjust: none;
	-webkit-font-smoothing: antialiased;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	&.menu-open {
		overflow:hidden;
	}
}

body,
input,
textarea,
select {
	font-family: 'Noto Sans HK', Arial, Helvetica, "微軟正黑體", "Microsoft JhengHei", "Heiti TC", "LiHei Pro", "新細明體", PMingLiU, sans-serif;
	color: #1A1A1A;
}

img {
	max-width:100%;
}

.position-md-absolute {
	@media (min-width:992px) { // md
		position:absolute;
	}
}

.text-decoration-underline {
	text-decoration:underline;
}

.highlight {
	color:#00A0E8;
	font-weight: 500;
}

.btn-style {
  display:inline-block;
  width:210px; height:60px;
  color:#fff;
  text-decoration: none;
  background-color:rgba(95,198,244,0.26);
  border:0;
  border-radius: 35px;
  padding:4px;
  font-size:16px;
  transition:.4s ease;
  &:hover {
    color:#fff;
    text-decoration: none;
    transform:scale(0.95);
    opacity:.8;
  }
  &.disable {
    pointer-events: none;
    background-color:rgba(191,191,191,0.26);
    opacity:0.3;
  }
	&.big-btn {
		font-size:24px;
		font-weight:100;
		height: 73px;
		@media (max-width:992px - 1px) { // md
			font-size:20px;
			width: 180px; height:54px;
		}
	}
  span {
    display:flex;
    justify-content: center;
    align-items: center;
    width:100%; height:100%;
    background-image: linear-gradient(150deg, #6CDDF8 0%, #3AB8EE 100%);
    border-radius: 30px;
		padding-bottom: 2px;
  }
}

/* ==========================================================================
   FONTS
   ========================================================================== */
// @font-face{
// 	font-family: 'OpenSans';
//     src: url(../fonts/OpenSans-Light.woff) , url(../fonts/OpenSans-Light.ttf) , url(../fonts/OpenSans-Light.svg) , url(../fonts/OpenSans-Light.eot);
//     font-weight: normal;
// };

.fontTimes {
	font-family: "Times New Roman", Arial, Helvetica, "微軟正黑體", "Microsoft JhengHei", "Heiti TC", "LiHei Pro", "新細明體", PMingLiU, sans-serif;
}

/* ==========================================================================
   ICONS
   ========================================================================== */
[class*='icon-'] {
	display: inline-block;
	vertical-align: middle;
	width: 50px;
	height: 50px;
	font-size: 50px;
	line-height: 1;
	background-size: 100% auto;

	svg {
		display: block;
		width: 100%;
		height: auto;
	}
}

/* ==========================================================================
   SETTING
   ========================================================================== */
pre {
	color: black;
	text-shadow: 0 1px white;
	font-family: Consolas, Monaco, 'Andale Mono', monospace;
	direction: ltr;
	text-align: left;
	white-space: pre-wrap;
	word-spacing: normal;
	word-break: normal;
	line-height: 1.5em;
	-moz-tab-size: 4;
	tab-size: 4;
	-webkit-hyphens: none;
	-ms-hyphens: none;
	hyphens: none;
	font-size: 13px;
	border: 1px solid #DFDFDF;
	background: #f9f9f9;
	padding: 20px;
	color: #333;
	border-radius: 4px;
}

.clear {
	clear: both;
}

.clearFix {
	zoom: 1;
}

.clearFix:after {
	clear: both;
	display: block;
	visibility: hidden;
	height: 0;
	line-height: 0;
	content: "";
}

.hide,
.desktop .onlyM,
.tablet .onlyM,
.mobile .onlyD {
	display: none !important;
}

.block {
	display: block;
}

.inlineBlock {
	display: inline-block !important;
	*display: inline !important;
	*zoom: 1 !important;
}

.fLeft {
	float: left;
}

.fRight {
	float: right;
}

.aCenter {
	text-align: center;
}

.aLeft {
	text-align: left;
}

.aRight {
	text-align: right;
}

.ghost {
	float: none;
	display: inline-block;
	*display: inline;
	*zoom: 1;
	height: 100%;
	vertical-align: middle;
}

.vaTop {
	float: none;
	display: inline-block;
	*display: inline;
	*zoom: 1;
	vertical-align: top;
}

.vaMiddle {
	float: none;
	max-width: 100%;
	display: inline-block;
	*display: inline;
	*zoom: 1;
	vertical-align: middle;
}

.img100 {
	position: relative;
	display: block;
	width: 100%;
}

/* ==========================================================================
   WRAPPER
   ========================================================================== */
.wrapper {
	position: relative;
	overflow:hidden;
	// min-width: $min-mobile-width;
	// width:100%;
	.inner-wrapper {
		// width:100%;
		transition:transform .4s ease;
		&.menu-open {
			// transform: translate(250px, 0);
			.menu-container {
				transform:translate(0, 0);
			}
		}
		.container {
			max-width:1080px;
		}
	}
}

.main {
	position: relative;
	min-width: 100%;
	min-height: 100%;
	overflow: hidden;
}

/* ==========================================================================
   LOADING
   ========================================================================== */
.loader {
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	opacity: 0;
	visibility: hidden;
	transition: 0.2s all;
	background-color: #fff;

	.loading & {
		opacity: 1;
		visibility: visible;
	}

	.loaderIcon {
		position: absolute;
		width: 50px;
		height: 50px;
		top: 50%;
		left: 50%;
		margin: -25px 0 0 -25px;
		font-size: 10px;
		text-indent: -12345px;
		border-top: 1px solid #bbb;
		border-right: 1px solid #bbb;
		border-bottom: 1px solid #bbb;
		border-left: 1px solid #fff;
		border-radius: 50%;
		animation: spinner 700ms infinite linear;
	}
}

/* ==========================================================================
   FONTS SIZE
   ========================================================================== */
.desktop,
.tablet {
	font-size: $font-size-desktop-body;
	line-height: 1.5em;
}

.mobile {
	font-size: $font-size-mobile-body;
	line-height: 1.5em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: normal;
}

h1,
.heading1 {
	line-height: 1.5em;

	.desktop &,
	.tablet & {
		font-size: $font-size-desktop-h1;
	}

	.mobile & {
		font-size: $font-size-mobile-h1;
	}
}

h2,
.heading2 {
	line-height: 1.5em;

	.desktop &,
	.tablet & {
		font-size: $font-size-desktop-h2;
	}

	.mobile & {
		font-size: $font-size-mobile-h2;
	}
}

h3,
.heading3 {
	line-height: 1.5em;

	.desktop &,
	.tablet & {
		font-size: $font-size-desktop-h3;
	}

	.mobile & {
		font-size: $font-size-mobile-h3;
	}
}

h4,
.heading4 {
	line-height: 1.5em;

	.desktop &,
	.tablet & {
		font-size: $font-size-desktop-h4;
	}

	.mobile & {
		font-size: $font-size-mobile-h4;
	}
}

p {
	line-height: 1.5em;

	.desktop &,
	.tablet & {
		font-size: $font-size-desktop-body;
	}

	.mobile & {
		font-size: $font-size-mobile-body;
	}

	a {
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}
}

a {
	color: color(black, 1);
	outline: none;
}

/* ==========================================================================
   SECTION
   ========================================================================== */
.section {
	position: relative;
}

.inner {
	position: relative;
	max-width: $max-desktop-width;
	margin: 0 auto;
	padding: 20px;
	box-sizing: border-box;
}

/* ==========================================================================
   COLUME
   ========================================================================== */
.colRow {
	margin-left: -10px;
	margin-right: -10px;
}

.col,
[class*="col-"] {
	.colInner {
		margin: 10px;
	}
}

@each $name,
$class in (dk-: ".desktop", tb-: ".tablet", mb-: ".mobile", all: "") {
	$numList: (2, 3, 4, 5);

	@if ($name=="all") {
		$name: "";
	}

	@each $num in $numList {
		@for $i from 1 through ($num - 1) {
			#{$class} .col-#{$name}#{$i}-#{$num} {
				width: calc($i / $num) * 100%;
			}
		}
	}

}

.desktop .desktop-100,
.tablet .desktop-100,
.mobile .mobile-100 {
	float: none;
	width: 100%;
	clear: both;
}

/* ==========================================================================
   GRIDBOX
   ========================================================================== */
[class*="gridBox-"] {
	position: relative;
}

.gridBox-tall {
	padding-bottom: 200%;
}

.gridBox-long-1-3 {
	padding-bottom: 120%;
}

.gridBox-square {
	padding-bottom: 100%;
}

.gridBox-long-1-4 {
	padding-bottom: 75%;
}

.gridBox-long-2-3 {
	padding-bottom: 60%;
}

.gridBox-long-1-2 {
	padding-bottom: 50%;
}

.gridBoxInner {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 0;
	margin: auto;
	background-size: cover;

	&.padding {
		top: 4px;
		left: 4px;
		right: 4px;
		bottom: 4px;
	}
}

/* ==========================================================================
   LAZY LOAD
	 ========================================================================== */
.lazy {
	position: relative;
	display: inline-block;
	min-width: 200px;
	min-height: 200px;
	background-color: #eee;
	background-image: url('../images/loading.gif');
	background-repeat: no-repeat;
	background-position: 50% 50%;

	img {
		transition: all .5s;
		opacity: 0;

		&[data-src],
		&[data-srcset] {
			display: block;
			min-height: 1px;
		}

		&.loaded {
			opacity: 1;
		}
	}
}

/* ==========================================================================
   SEP
   ========================================================================== */
.sep {
	display: block;
	height: 1px;
	width: 100%;
	margin: 20px 0;
	background-color: #eee;
}

/* ==========================================================================
   LIST STYLE
	 ========================================================================== */
ol {
	padding-left: 20px;
	li {
		&.sublist {
			counter-increment: ordem;
			ol {
				counter-reset: sublist;
				padding-left: 12px;
				& > li {
					position:relative;
					list-style:none;
					padding-left:35px;
					&:before {
						position:absolute;
						top:0; left:0;
						counter-increment: sublist;
						content: counter(ordem)"." counter(sublist)" ";
					}
				}
			}
		}
	}
}

ul.disc {
	list-style: disc;
	padding-left: 20px;
}

ul.decimal {
	list-style: decimal;
	padding-left: 20px;
}

ul.lower-alpha {
	list-style: lower-alpha;
	padding-left: 20px;
}

ul.upper-alpha {
	list-style: upper-alpha;
	padding-left: 20px;
}

ul.lower-roman {
	list-style: lower-roman;
	padding-left: 20px;
}

ol li {
	line-height: 1.5em;
	margin: 0.5em 0;
}
ul.disc li,
ul.decimal li,
ul.lower-alpha li,
ul.lower-roman li {
	line-height: 1.5em;
	margin: 0.5em 0;
	&:before {
		counter-increment: none !important;
		content: none !important;
	}
}

/* ==========================================================================
   BORDER
	 ========================================================================== */
.border {
	border: 1px solid #000 !important;
}

/* ==========================================================================
   INPUT
   ========================================================================== */
label {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: pointer;
}

input[type="text"],
input[type="tel"],
input[type="email"],
textarea,
select {
	display: block;
	line-height: 1.3em;
	width: 100%;
	// border: 1px solid color(black, 2);
	border-radius: 0;
	background-color: #fff;
	outline: none;
	box-sizing: border-box;
	transition: all 200ms;
	-webkit-appearance: none;
	font-size: 16px;
	@media (max-width:992px - 1px) { // md
		font-size:14px;
	}
}

input[type="text"],
input[type="tel"],
input[type="email"],
select {
	padding: 17px 15px;
	// height: $input-height;
}

textarea {
	height: 100px;
	padding: 10px;
	resize: none;
}

input:focus,
textarea:focus,
select:focus,
input:hover,
textarea:hover,
select:hover {
	border-color: color(black, 1);
}

input[type="checkbox"] {
	position: absolute;
	visibility: hidden;
	width: 0;
	height: 0;
}

.checkbox {
	position: relative;
	display: block;
	width: 20px;
	height: 20px;
	background: #ffffff;
	border: 1px solid color(black, 1);
	overflow: hidden;
}

input[type="checkbox"]+.checkbox [class*="icon-"] {
	width: 0;
	height: 100%;
	font-size: 20px;
	background-size: auto 100%;
	display: inline-block;
	transition: width 0.3s;
	overflow: hidden;
}

input[type="checkbox"]:checked+.checkbox [class*="icon-"] {
	width: 100%;
}

input[type="radio"] {
	position: absolute;
	visibility: hidden;
	width: 0;
	height: 0;
}

.radiobox {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	width: 20px;
	height: 20px;
	margin-right: 10px;
	background: #ffffff;
	border: 1px solid color(black, 1);
	box-sizing: border-box;
	border-radius: 20px;
	padding: 2px;
	overflow: hidden;
}

input[type="radio"]+.radiobox:before {
	content: '';
	width: 100%;
	height: 100%;
	display: block;
	background: #000;
	transition: all 0.3s;
	border-radius: 20px;
	transform: scale(0);
}

input[type="radio"]:checked+.radiobox:before {
	transform: scale(1);
}

/* ==========================================================================
   COLOR
   ========================================================================== */
// .color-blue-1 to .color-blue-2
// .bgColor-blue-1 to .bgColor-blue-2

@each $color-group,
$array in $color-array {
	@for $i from 0 to length($array) {
		.color-#{""+$color-group}-#{$i+1} {
			color: nth($array, $i+1);

			svg path {
				fill: nth($array, $i+1);
			}
		}

		.bgColor-#{""+$color-group}-#{$i+1} {
			background-color: nth($array, $i+1);
		}
	}
}

/* ==========================================================================
   BUTTON
   ========================================================================== */
.commonBtn {
	position: relative;
	display: block;
	padding: 15px 20px;
	width: 100%;
	background-color: transparent;
	border: 2px solid #000;
	color: color(black, 1);
	font-size: 14px;
	line-height: 1;
	text-align: center;
	overflow: hidden;
	text-decoration: none;
	box-sizing: border-box;
	transition: all 0.3s;

	span {
		position: relative;
	}

	&:hover {
		color: color(black, 2);
		border-color: color(black, 2);
	}
}