/* ==========================================================================
   Z-index
   ========================================================================== */
.loader,
.loadbox1,
.loadbox2{
	z-index: 99999;
}
.headerWrapper{
	z-index: 50;
}
.mobileHeader {
	z-index: 51;
}

.dimBg {
	z-index: 40;
}
