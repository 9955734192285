@function color($color-group, $index) {
	@return nth( map-get($color-array, $color-group), $index );
}

$color-array: (
	black: ( #000 , #999 , #f8f8f8 ),
	red: ( #B90220, #BA2028 )
);

$input-height : 40px;

$font-size-desktop-body : 16px;
$font-size-desktop-h1: 40px;
$font-size-desktop-h2: 30px;
$font-size-desktop-h3: 24px;
$font-size-desktop-h4: 18px;

$font-size-mobile-body : 14px;
$font-size-mobile-h1: 25px;
$font-size-mobile-h2: 20px;
$font-size-mobile-h3: 18px;
$font-size-mobile-h4: 16px;

$max-desktop-width : 1200px;
$min-mobile-width : 320px;
